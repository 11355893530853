// environment variable
const SERVER_PATH = window._env_.SERVER_PATH;

/** BE API_ADDRESS
 * GET_SEARCH_LIST : 全件検索・検索条件・タグ一覧
 * COUNT_VIDEO：ビデオ再生際に再生数を+1
 * PLAY_MOVIE_ID：ビデオ詳細画面
 * REGISTER_VIDEO: DynamoDBへ動画情報登録
 * GET_IMFORMATION: メンテナンスお知らせ取得
 */
const API_ADD = {
  GET_SEARCH_LIST: `${SERVER_PATH}/api/getSearchList`,
  COUNT_VIDEO: `${SERVER_PATH}/api/countVideo`,
  COUNT_UP_LIKE: `${SERVER_PATH}/api/countUpLike`,
  PLAY_MOVIE_ID: (videoId) => `${SERVER_PATH}/api/playMovie?videoId=${videoId}`,
  GET_VIDEO_LIST: `${SERVER_PATH}/api/getVideoList`,
  DELETE_VIDEO_ID: `${SERVER_PATH}/api/deleteVideo`,
  REGISTER_VIDEO: `${SERVER_PATH}/api/registerVideo`,
  GET_REGIST_STATUS: (videoId) =>
    `${SERVER_PATH}/api/getStatus?videoId=${videoId}`,
  GET_SERVER_VERSION: `${SERVER_PATH}/api/getServerVersion`,
  EDIT_VIDEO: `${SERVER_PATH}/api/editVideo`,
  ISSUE_URL: `${SERVER_PATH}/api/issueURL`,
  GET_IMFORMATION: `${SERVER_PATH}/api/getInformation`,
  GET_TAG_LIST: `${SERVER_PATH}/api/getTagList`,
  GET_TAG_VIDEO: `${SERVER_PATH}/api/getSubTagVideo`,
  GET_USER_INFORMATION: (email) =>
    `${SERVER_PATH}/api/getUserInformation?email=${email}`,
};

export default API_ADD;
