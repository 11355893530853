import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ビデオカードリストコンポーネント
import VideoCard from "./VideoCard.js";

/**
 * ドコモのトップ・検索結果
 * @parmas : data getSearchListリクエスト
 * @parmas : viewFlg 画面フラグ top : 0 / tag : 1 / 検索 : 2 / マイページいいね: 3 / マイページ視聴履歴: 4
 * @parmas : dataLength getSearchListの動画情報数
 */
function VideoCardList({
  data,
  viewFlg,
  lastFlg,
  userEmail,
  refreshLikedVideo,
  dataLength,
  tag,
}) {
  // リアルタイムで画面サイズの変更を検知・値を取得
  const sortedDataByViews =
    viewFlg === 0 ? [...data].sort((a, b) => b.views - a.views) : null;

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  // ブラウザの幅により動画カード表示設定
  useEffect(() => {
    if (windowDimensions.width >= 1536) setCardCount(8);
    else if (windowDimensions.width >= 1200) setCardCount(6);
    else if (windowDimensions.width >= 900) setCardCount(4);
  }, [windowDimensions]);
  // 動画カードの表示数
  const [cardCount, setCardCount] = useState(6);

  const navigate = useNavigate();

  const displayCardCount = viewFlg === 4 ? 21 : cardCount;

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "end" }}>
        <Typography
          variant="h4"
          color="inherit"
          noWrap
          sx={{ minWidth: "800px" }}
        >
          {viewFlg === 0 ? "新着動画" : viewFlg === 1 ? tag : null}
          <span style={{ fontSize: 20 }}>
            {viewFlg === 1 || viewFlg === 2
              ? `(検索結果 ${dataLength}件)`
              : undefined}
          </span>
        </Typography>
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {
            /* タグ内の動画単位の繰り返し処理 topの場合、5件まで表示表示 */
            data
              .slice(
                0,
                viewFlg === 0 || viewFlg === 4 ? displayCardCount : undefined,
              )
              .map((contents, j) => (
                <VideoCard
                  key={j}
                  contents={contents}
                  viewFlg={viewFlg}
                  userEmail={userEmail}
                  onLikeClick={refreshLikedVideo}
                />
              ))
          }
        </Grid>
        <Grid container spacing={1}>
          {viewFlg === 0 && (
            <Button
              onClick={() => navigate(`/top`, { state: { sort: 1 } })}
              size="large"
              sx={{ mx: "auto" }}
            >
              もっと見る
            </Button>
          )}
          {viewFlg === 1 && (
            <Button href={"/top"} size="large" sx={{ mx: "auto" }}>
              Topへ戻る
            </Button>
          )}
        </Grid>
      </Box>
      {viewFlg === 0 && (
        <>
          <Grid container spacing={1}>
            <Typography
              variant="h4"
              color="inherit"
              noWrap
              sx={{ minWidth: "800px", marginTop: "30px" }}
            >
              よく見られている動画
            </Typography>
          </Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {
                /* タグ内の動画単位の繰り返し処理 topの場合、5件まで表示表示 */
                sortedDataByViews
                  .slice(0, viewFlg !== 0 ? undefined : cardCount)
                  .map((contents, j) => (
                    <VideoCard key={j} contents={contents} />
                  ))
              }
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              {viewFlg === 0 && (
                <Button
                  onClick={() => navigate(`/top`, { state: { sort: 3 } })}
                  size="large"
                  sx={{ mx: "auto" }}
                >
                  もっと見る
                </Button>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default VideoCardList;
