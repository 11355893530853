import React from "react";
import preval from "preval.macro";
import dayjs from "dayjs";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Version() {
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const lastUpdateDayjs = dayjs.unix(buildTimestamp / 1000); // moment.unix()と同様の方法でUnixタイムスタンプを解析
  const formattedDate = lastUpdateDayjs.format("YYYY/MM/DD HH:mm:ss");

  return (
    <>
      <Box>
        <Typography variant="h5">
          クライアント側リリースバージョン：{window._env_.RELEASE_VERSION}
        </Typography>
        <Typography variant="h5">Build Date：{formattedDate}</Typography>
      </Box>
    </>
  );
}

export default Version;
