import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import VideoCardList from "../components/VideoCardList.js";

function MyPage({ getUserApiResult }) {
  const [userInformation, setUserInformation] = useState({});
  const [viewHistoryInformation, setViewHistoryInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(true);
  const [likedVideos, setLikedVideos] = useState([]);
  const [viewHistoryVideos, setViewHistoryVideos] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const extraContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-more">
      ...さらに表示
    </span>
  );

  const lessContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-less">
      ...閉じる
    </span>
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const userInformationResponse = await API.graphql({
          query: queries.getUserInformation,
          variables: variables,
        });
        setUserInformation(
          userInformationResponse.data.getUserInformation.userData,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const historyFetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const viewHistoryResponse = await API.graphql({
          query: queries.getViewHistory,
          variables: variables,
        });
        setViewHistoryInformation(
          viewHistoryResponse.data.getViewHistory.viewHistory,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    historyFetchData();
  }, [getUserApiResult, refresh]); // fetchDataは一度だけ実行する

  useEffect(() => {
    const fetchLikedVideoInfo = async () => {
      let tempArray = [];
      const likedVideoidList = userInformation.liked_videoid_list;
      if (likedVideoidList && likedVideoidList.length > 0) {
        for (const videoid of likedVideoidList) {
          await API.graphql({
            query: queries.playMovie,
            variables: { filter: { id: { eq: videoid } } },
          }).then((result) => {
            if (result.data.playMovie.method.length > 0) {
              const video = result.data.playMovie.method[0];
              if (video.status === "COMPLETE") {
                tempArray.push(video);
              } else if (video.status === "DELETED") {
                tempArray.push({
                  ...video,
                  setting_thumbnail_url: "Thumbnail_DELETED",
                });
              }
            }
          });
        }
      }
      setLikedVideos(tempArray);
      setLoading(false);
    };

    if (userInformation.liked_videoid_list) {
      fetchLikedVideoInfo();
    }
  }, [userInformation]); // userInformationが更新された時にfetchLikedVideoInfoを実行

  // viewHistoryInformationの変更を監視し、変更があった場合にfetchViewHistoryInfoを実行
  useEffect(() => {
    const fetchViewHistoryInfo = async () => {
      let viewHistoryArray = [];
      // console.log(viewHistoryInformation);
      if (viewHistoryInformation && viewHistoryInformation.length > 0) {
        for (const historyVideoId of viewHistoryInformation) {
          await API.graphql({
            query: queries.playMovie,
            variables: { filter: { id: { eq: historyVideoId } } },
          }).then((result) => {
            if (result.data.playMovie.method.length > 0) {
              const video = result.data.playMovie.method[0];
              if (video.status === "COMPLETE") {
                viewHistoryArray.push(video);
              } else if (video.status === "DELETED") {
                viewHistoryArray.push({
                  ...video,
                  setting_thumbnail_url: "Thumbnail_DELETED",
                });
              }
            }
          });
        }
      }
      setViewHistoryVideos(viewHistoryArray);
      setLoading(false);
    };

    if (viewHistoryInformation && viewHistoryInformation.length > 0) {
      fetchViewHistoryInfo();
    }
  }, [viewHistoryInformation]);

  if (loading) {
    return <div></div>;
  }

  return (
    <div>
      <Card sx={{ maxWidth: "100%", bgcolor: blueGrey[50], margin: 4 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                alt="User Image"
                src={userInformation.profile_picture}
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
            <Grid item xs={12} sm container>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h4" component="div">
                  {userInformation.user_name}
                </Typography>
                <Typography variant="h6" color="text.primary">
                  プロフィール
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userInformation.profile_text
                    ? readMore
                      ? userInformation.profile_text.length > 100
                        ? userInformation.profile_text.substring(0, 100)
                        : userInformation.profile_text
                      : userInformation.profile_text
                    : ""}
                  {userInformation.profile_text &&
                    userInformation.profile_text.length > 100 &&
                    (readMore ? extraContent : lessContent)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab
          label="いいねした動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
        <Tab
          label="視聴した動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
      </Tabs>
      {selectedTab === 0 && (
        <div>
          {likedVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              いいねした動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={likedVideos}
              viewFlg={3}
              lastFlg=""
              showHeartButton={true}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={likedVideos.length}
            />
          )}
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          {viewHistoryVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              視聴した動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={viewHistoryVideos}
              viewFlg={4}
              lastFlg=""
              showHeartButton={false}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={viewHistoryVideos.length}
            />
          )}
        </div>
      )}
    </div>
  );
}
export default MyPage;
