import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Box from "@mui/system/Box";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SubTagsForCard from "./SubTagsForCard.js";
import MyPageFavButton from "../components/MyPageFavButton.js";

// 削除された動画用のサムネイルファイル
import ThumbnailDeletedImage from "../assets/deleted.png";

/**
 * ビデオ情報を表示するカード
 * @parmas contents : 一個video情報
 * @return : 一個のvideo情報カードUI
 */

function VideoCard({ contents, viewFlg, userEmail, onLikeClick }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const isDescription = contents.description[0].length > 0;

  // サムネイル画像の選択
  const thumbnailImage = contents.setting_thumbnail_url
    ? contents.setting_thumbnail_url === "Thumbnail_DELETED"
      ? ThumbnailDeletedImage
      : contents.setting_thumbnail_url
    : contents.thumbnail_url;

  return (
    <Grid lg={4} xl={3}>
      <Item elevation={0}>
        <Card
          sx={{
            maxWidth: 345,
            textAlign: "left",
          }}
          onClick={() => navigate(`/playmovie/${contents.id}`)}
        >
          <CardActionArea>
            <div style={{ position: "relative" }}>
              {viewFlg === 3 && (
                <MyPageFavButton
                  contents={contents}
                  userEmail={userEmail}
                  onLikeClick={onLikeClick}
                />
              )}
              <Tooltip
                placement="bottom-start"
                variant="outlined"
                title={contents.description}
                followCursor
                arrow
                disableHoverListener={!isDescription}
              >
                <div>
                  <CardMedia
                    component="img"
                    image={thumbnailImage} // 選択したサムネイル画像を設定
                    alt="thumbnail"
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      position: "absolute",
                      bottom: 5,
                      right: 5,
                      borderRadius: 1,
                      p: 0.5,
                      fontSize: "0.75rem",
                      lineHeight: "0.6rem",
                    }}
                  >
                    {contents.video_time}
                  </Typography>
                </div>
              </Tooltip>
            </div>
            <Tooltip
              placement="bottom-start"
              variant="outlined"
              title={contents.description}
              followCursor
              arrow
              disableHoverListener={!isDescription}
            >
              <div>
                <CardContent
                  sx={{
                    height: 80,
                    maxHeight: 80,
                    maxWidth: 345,
                    overflow: "hidden",
                    p: 1,
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    sx={{
                      height: 70,
                      maxHeight: 70,
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                      lineHeight: 1.5,
                      m: 0,
                    }}
                  >
                    {contents.title}
                  </Typography>
                </CardContent>
                <CardContent sx={{ p: 1, height: 65 }}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      更新日: {contents.update_date.split(" ", 1)}
                    </Typography>
                    <Box ml={2}>
                      <Typography variant="body2" color="text.secondary">
                        再生数: {contents.views}
                      </Typography>
                    </Box>
                    <Box ml={2} display="flex" alignItems="center">
                      <ThumbUpIcon
                        fontSize="inherit"
                        sx={{ color: theme.palette.text.secondary }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        &nbsp;{contents.likes}
                      </Typography>
                    </Box>
                  </Box>
                  {contents.subtags && (
                    <SubTagsForCard subtags={contents.subtags} />
                  )}
                </CardContent>
              </div>
            </Tooltip>
          </CardActionArea>
        </Card>
      </Item>
    </Grid>
  );
}
export default VideoCard;
