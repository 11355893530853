import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// ビデオカードリストコンポーネント
import VideoCardList from "../components/VideoCardList.js";
// BE API
//import API_ADD from "../config.js";
import NotFound from "./NotFound.js";
// /docopa-dev-client/src/views/NotFound.js

//APIリクエスト
import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

/**
 * タグリスト画面
 */
function TagVideoList() {
  // getSearchListリクエスト
  const [data, setData] = useState([]);
  const [notTag, setNotTag] = useState(false);
  const location = useLocation();

  // locationからtagの情報取得
  const tag = (location.state && location.state.tagName) || null;

  useEffect(() => {
    // もっと見るをクリックしたタグデータ取得
    if (!tag) {
      setNotTag(true);
      return;
    }
    // pramsからtags取得
    const fetchData = async () => {
      try {
        const res = await API.graphql(
          graphqlOperation(queries.getSearchList, { tag: tag }),
        );
        setData(res.data.getSearchList.video_list);
      } catch (e) {
        console.log(e);
      }
    };
    if (location.state) fetchData();
  }, [location.state, tag]);
  // タグ名のパラメータが場合
  if (notTag) return <NotFound />;
  return (
    <>
      <VideoCardList
        data={data}
        viewFlg={1}
        lastFlg={true}
        style={{ backgroundColor: "gray" }}
        tag={tag}
        dataLength={data.length}
      />
    </>
  );
}
export default TagVideoList;
