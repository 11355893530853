// src/SecureRoute.js

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const SecureRoute = ({ component: Component, ...rest }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const revokeAndRedirect = async () => {
      if (!authState) {
        // Loading
        return;
      }

      if (!authState.isAuthenticated) {
        if (authState.accessToken) {
          // Revoke the access token
          await oktaAuth.revokeAccessToken();
        }
        const originalUri = location.pathname;
        oktaAuth.setOriginalUri(originalUri);
        // navigate("/login/callback");
        oktaAuth.signInWithRedirect();
      }
    };
    revokeAndRedirect();
  }, [authState, oktaAuth, navigate, location]);

  if (!authState || !authState.isAuthenticated) {
    return <div>Loading...</div>;
  }

  return <Component {...rest} />;
};

export default SecureRoute;
