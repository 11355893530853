// 必須・任意の設定内容
const checkReq = {
  required: {
    color: "red",
    text: "必須",
  },
  elective: {
    color: "gray",
    text: "任意",
  },
};

// helperText・errText
const explanText = {
  title: {
    helperText: "動画のタイトルを入力してください。",
    errText: "動画のタイトルは必須です。",
  },
  tag: {
    helperText:
      "動画につけるカテゴリを選択してください。(カテゴリは1種類のみ指定可能)",
    errText: "動画につけるカテゴリは必須です。",
  },
  video: {
    helperText: "動画を選択してください。",
    EditHelperText: "動画を変更する場合は、ファイルを選択してください。",
    nonErrText: "動画ファイルは必須です。",
    otherFileErrText: "動画ファイルではありません。",
    fileSizeErrText: "ファイルサイズが1000Mを超えています。",
  },
  thumbnail: {
    helperText: `サムネイル画像を選択してください。（JPG, JPEG, PNG, GIF）
     ※サムネイル画像を選択しない場合は、自動で生成された画像を表示します。`,
    EditHelperText:
      "サムネイル画像を変更する場合は、ファイルを選択してください。",
    errText: "画像ファイルではありません。",
  },
  subTags: {
    helperText: `タグを入力してください。タグは「,」で区別してください。
    （最大5件、一つのタグは15文字まで）`,
    errText: "タグは最大5件までです。",
    errMaxTagLength: "一つのタグは15文字までです。",
  },
};

/**
 * ファイル名正規表現置換
 * @params : filename
 * @return : ;,/?:@&=+$!.~*'^{}()|[]\#空白(スペース)をなくしたfilename
 */
const checkFileName = (filename) => {
  // 拡張子取得
  const extension = filename.substring(
    filename.lastIndexOf(".") + 1,
    filename.length,
  );
  // ファイル名取得&変換
  const fileNameOnly = filename
    .substring(0, filename.lastIndexOf("."))
    .replace(/[;,/?:@%&=+$!.~*'^{}()|[\]\\#\s]/g, "_");

  return `${fileNameOnly}.${extension}`;
};

export { checkReq, explanText, checkFileName };
