import React, { useState, useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import { useNavigate } from "react-router-dom";

import headerDocopaLogo from "./../img/headerDocopaLogo.ico";

import { useOktaAuth } from "@okta/okta-react";

/**
 * ドコパヘッダーコンポーネント共通か
 * @param isManagePath 管理者URL：true
 * @param cursor カーソル表示（ログイン画面）：false
 * @param handleLogoClick ロゴのオンクリックイベント
 * @returns ドコパヘッダーコンポーネント
 */
function DocopaHeader({
  isManagePath,
  cursor,
  handleLogoClick,
  getUserApiResult,
}) {
  const [userInformation, setUserInformation] = useState({});
  const { oktaAuth } = useOktaAuth();

  // ログアウト処理
  const handleLogout = async () => {
    //課題：accessTokenをエラーが出ないようにrevokeするよう修正
    await oktaAuth.signOut({ revokeAccessToken: false });
  };

  // カーソルの有効化：ログイン画面不可能
  const cursorStyle = cursor ? { cursor: "pointer" } : null;

  useEffect(() => {
    try {
      setUserInformation(getUserApiResult);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [getUserApiResult]);

  const navigate = useNavigate();

  const handleMyPage = () => {
    navigate("/mypage");
  };

  return (
    <AppBar position="fixed">
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Stack direction="row" onClick={handleLogoClick} style={cursorStyle}>
          <img alt="" src={headerDocopaLogo} width="40" />
          <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
            Docopa
          </Typography>
          {isManagePath ? (
            <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
              管理者
            </Typography>
          ) : null}
        </Stack>
        {getUserApiResult && (
          <Stack direction="row" spacing={2}>
            <IconButton onClick={handleMyPage}>
              <Avatar
                alt="User Icon"
                src={userInformation ? userInformation.profile_picture : null}
              />
            </IconButton>
            <Button color="inherit" onClick={handleLogout}>
              SIGN OUT
            </Button>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DocopaHeader;
