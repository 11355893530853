import * as React from "react";
import { Menu, MenuItem } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import IconButton from "@mui/material/IconButton";
//import Button from "@mui/material/Button";

import { API } from "@aws-amplify/api";
import { countUpLike } from "../graphql/mutations";

function MyPageFavButton({ contents, userEmail, onLikeClick }) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  async function handleLikeClick(event) {
    event.stopPropagation();
    const variables = {
      id: contents.id,
      email: userEmail,
      register_date: contents.register_date,
    };
    console.log(contents.id);
    await API.graphql({ query: countUpLike, variables: variables });
    onLikeClick();
    handleClose(event);
  }
  return (
    <div>
      <IconButton
        id="fav-button"
        color="secondary"
        aria-label="add to favorites"
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          border: "1px solid white",
          borderRadius: "50%",
          backgroundColor: "rgba(255,255,255,0.7)",
          "&hover": {
            backgroundColor: "rgba(255,255,255,0.9)",
          },
        }}
        onClick={handleClick}
      >
        <FavoriteIcon
          sx={{
            color: "#FF6961",
          }}
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLikeClick}>
          いいねした動画から削除する
        </MenuItem>
      </Menu>
    </div>
  );
}
export default MyPageFavButton;
