import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Chip from "@mui/material/Chip";

function SubTagsForCard({ subtags }) {
  return (
    <Grid
      direction="row"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {subtags.map((tag, i) => (
        <Chip
          label={`#${tag}`}
          size="small"
          key={i}
          style={{
            marginRight: "3px",
          }}
        />
      ))}
    </Grid>
  );
}

export default SubTagsForCard;
